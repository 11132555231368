<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> 設定任務關卡 － </span>
      <span class="text-h5">
        {{ missionName }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container v-if="editItem">
        <v-form ref="editForm" lazy-validation>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                v-model="editItem.missionName"
                label="關卡名稱"
                :rules="rules.required"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editItem.missionDesc"
                outlined
                label="關卡描述"
                rows="3"
                class="font-size-input-lg input-style"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="editItem.data.rewardId"
                label="關卡獎勵"
                :items="availableRewards"
                item-text="rewardName"
                item-value="rewardId"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
                clearable
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="false">
              <v-checkbox
                v-model="editItem.data.canViewResult"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                :class="$vuetify.breakpoint.xs ? '' : 'pt-6'"
                label="可查看調查結果"
                :disabled="true || isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" v-if="false">
              <v-checkbox
                v-model="editItem.data.repeatable"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                :class="$vuetify.breakpoint.xs ? '' : 'pt-6'"
                label="可重複作答"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" v-if="false">
              <v-checkbox
                v-model="editItem.data.multiCollectible"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                :class="$vuetify.breakpoint.xs ? '' : 'pt-6'"
                label="可重複領獎"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col xs="6" sm="4" lg="3" cols="12">
                  <v-card class="px-4 py-4 card-shadow border-radius-xl h-100">
                    <div
                      class="
                        d-flex
                        flex-column
                        justify-center
                        text-center
                        h-100
                      "
                    >
                      <a
                        @click="onEditQuestion(null)"
                        class="text-decoration-none"
                      >
                        <i
                          class="fa fa-plus text-secondary mb-3"
                          aria-hidden="true"
                        ></i>
                        <h5 class="text-h5 text-secondary">新增題目</h5>
                      </a>
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  v-for="(q, index) in editItem.data.questions"
                  :key="index"
                  xs="6"
                  sm="4"
                  lg="3"
                  cols="12"
                >
                  <v-card
                    class="card-shadow border-radius-xl py-4 text-left h-100"
                  >
                    <v-row no-gutters class="px-4">
                      <v-col cols="12">
                        <p
                          class="
                            text-md
                            mb-1
                            text-capitalize text-body
                            font-weight-bold
                            truncate
                          "
                        >
                          {{ q.title }}
                        </p>
                        <!-- <p
                          class="
                            text-sm
                            mb-1
                            text-capitalize text-body
                            font-weight-light
                            truncate
                          "
                        >
                          {{ q.desc }}
                        </p> -->
                      </v-col>
                    </v-row>
                    <v-row class="px-3 mt-1">
                      <v-col cols="12" class="pt-0">
                        <p class="mb-0 text-body">
                          <span class="font-weight-light ms-1"> 類型： </span>
                          <span class="text-success text-sm font-weight-bolder">
                            {{ getQuestionType(q.type) }}
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="px-3 mt-1">
                      <v-col cols="12" class="pt-0">
                        <p class="mb-0 text-body">
                          <span class="font-weight-light ms-1"> 必填： </span>
                          <span class="text-success text-sm font-weight-bolder">
                            <v-icon v-if="q.required" color="green darken-2">
                              fa-solid fa-check
                            </v-icon>
                            <v-icon v-else color="red darken-2">
                              fa-solid fa-times
                            </v-icon>
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <hr class="dark horizontal mt-3 mb-4" />
                    <v-row class="px-4">
                      <v-col sm="12" class="">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mx-2"
                              dark
                              small
                              color="cyan"
                              v-on="on"
                              @click="onEditQuestion(q, index)"
                            >
                              <v-icon>fas fa-edit</v-icon>
                              {{ $t(`common['Edit']`) }}
                            </v-btn>
                          </template>
                          <span> {{ $t(`common['Edit']`) }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-dialog v-model="openQuestionDialog" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">
                {{ isNewQuestion ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
              </span>
              <span class="text-h5"> 題目 </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="questionForm" lazy-validation>
                  <v-row class="mx-auto">
                    <v-col cols="12">
                      <v-select
                        :items="questionTypes"
                        v-model="editQuestion.type"
                        label="題目類型"
                        item-value="value"
                        item-text="text"
                        class="font-size-input-lg input-style"
                        :rules="rules.required"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-text-field
                        v-model="editQuestion.title"
                        label="題目"
                        :rules="rules.required"
                        class="font-size-input-lg input-style"
                      ></v-text-field> -->
                      <v-textarea
                        v-model="editQuestion.title"
                        outlined
                        label="題目"
                        :rules="rules.required"
                        rows="3"
                        class="font-size-input-lg input-style"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editQuestion.desc"
                        class="font-size-input-lg input-style"
                        label="描述"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="editQuestion.required"
                        color="#141727"
                        class="large-checkbox"
                        hide-details
                        label="必填"
                        :disabled="true"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editQuestion.type === 'multi-choice'"
                    >
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model.number="editQuestion.minSelection"
                            label="最少須選取"
                            type="number"
                            class="font-size-input input-style"
                            :rules="
                              editQuestion.type === 'multi-choice' &&
                              editQuestion.required
                                ? rules.minSelection
                                : []
                            "
                            min="1"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editQuestion.maxSelection"
                            label="最多可選取（不填寫代表不限制）"
                            type="number"
                            class="font-size-input input-style"
                            :rules="rules.maxSelection"
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="mx-auto">
                        <v-text-field
                          v-model="inputOption"
                          label="答案選項"
                          class="font-size-input-lg input-style"
                          @keyup.enter="addAnswer"
                        >
                          <template v-slot:append-outer>
                            <v-btn @click="addAnswer" small dark class="mx-2">
                              <v-icon> fa-lg fa-plus </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-row>
                      <v-row class="mx-auto" v-if="options.length">
                        <v-simple-table class="w-100">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">#</th>
                                <th class="text-left" min-width="100px">
                                  選項
                                </th>
                                <th class="text-left" width="10%">答案</th>
                                <th class="text-left" width="10%"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in options" :key="index">
                                <td>
                                  {{ index + 1 }}
                                </td>
                                <td @click="onClickOption(index)">
                                  <div class="align-center text-center pt-7">
                                    <v-text-field
                                      :disabled="!item.editable"
                                      v-model="item.text"
                                      outlined
                                      :ref="'input-' + index"
                                      class="font-size-input-lg input-style"
                                      :rules="rules.required"
                                      @keyup.enter="item.editable = false"
                                    >
                                    </v-text-field>
                                  </div>
                                </td>
                                <td>
                                  <v-list-item-action>
                                    <v-btn
                                      class="mx-2"
                                      dark
                                      fab
                                      x-small
                                      color="pink darken-2"
                                      @click="removeOption(index)"
                                    >
                                      <v-icon>fas fa-trash</v-icon>
                                    </v-btn>
                                  </v-list-item-action>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeQuestionDialog">
                {{ $t(`common['Close']`) }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveEditQuestion">
                {{ $t(`common['Save']`) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        @click="closeEditDialog"
      >
        {{ $t(`common['Close']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        :loading="isProcessing"
        @click="onSaveEditItem"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchCampaignRewards, updateMission } from "src/apis/campaignCore";
import { makeid } from "src/util/utils";
import variables from "src/util/variables";

export default {
  name: "QuestionnaireForm",
  props: {
    inputItem: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    missionName: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      moment: moment,
      editItem: null,
      isProcessing: false,
      isLoadingRewards: false,
      availableRewards: [],
      openQuestionDialog: false,
      defaultEditQuestion: {
        index: -1,
        required: true,
        minSelection: null,
        maxSelection: null,
      },
      editQuestion: {},
      isNewQuestion: false,
      inputOption: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        minSelection: [
          (v) => {
            if (v < 1) return "最小為1";
            return true;
          },
        ],
        maxSelection: [
          (v) => {
            if (v && v <= this.editQuestion.minSelection)
              return `需要大於${this.editQuestion.minSelection}`;
            return true;
          },
        ],
      },
      options: [],
      tableOptionsHeaders: [
        {
          text: "#",
          sortable: false,
          value: "sn",
        },
        {
          text: "選項",
          sortable: false,
          value: "text",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return this.isProcessing || this.isLoadingRewards;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    isNew() {
      return this.editItem.missionId ? false : true;
    },
    questionTypes() {
      let types = variables.questionTypes.filter(
        (item) => item.isQuestionnaireSuppported
      );
      // if (types.length === 1) {
      //   this.editQuestion.type = types[0].value;
      // }
      return types;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.editItem = Object.assign({}, this.inputItem);
    if (!this.editItem.missionId) {
      // new mission
      this.editItem.data = {
        rewardIds: [],
        questions: [],
        canViewResult: true,
      };
    } else {
      this.editItem.data = Object.assign({}, this.editItem.settings);
      if (!this.editItem.data.canViewResult) {
        this.editItem.data.canViewResult = true;
      }
    }
    // if (this.editQuestion.rewardIds)
    //   this.editItem.rewardId = this.editQuestion.rewardIds[0];
    this.getRewards();
  },
  methods: {
    onClickOption(index) {
      this.options[index].editable = true;
      setTimeout(() => {
        this.$refs[`input-${index}`][0].focus();
      }, 1);

      // this.options.forEach((o, idx) => {
      //   if (idx === index) {
      //     o.editable = true;
      //   } else {
      //     o.editable = false;
      //   }
      // });
    },
    removeOption(index) {
      // console.log("remove option:", o);
      this.options.splice(index, 1);
    },
    getQuestionType(t) {
      let filtered = this.questionTypes.filter((q) => q.value === t);
      return filtered.length ? filtered[0].text : "";
    },
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.availableRewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.$emit("close");
    },
    onSaveEditItem() {
      this.editItem.name = this.editItem.missionName;
      this.editItem.desc = this.editItem.missionDesc;
      // this.editItem.entryUrl = variables.entryUrls["questions"];
      this.editItem.entryUrl = `/qa/${this.campaignId}`;
      this.editItem.data.rewardIds = this.editItem.data.rewardId
        ? [this.editItem.data.rewardId]
        : [];
      if (this.editItem.data.rewardIds.length === 0) {
        this.$swal({
          text: "此關卡尚未設定獎勵，請確認是否儲存？",
          type: "info",
          showCancelButton: true,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          cancelButtonText: this.$i18n.t(`common["Cancel"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        }).then((res) => {
          if (res.isConfirmed) {
            this.save();
          }
        });
      } else {
        this.save();
      }
    },
    save() {
      const valid = this.$refs.editForm.validate();
      this.isProcessing = true;
      if (valid) {
        // this.$emit("save");
        updateMission(this.merchantId, this.campaignId, this.editItem)
          .then((res) => {
            console.log("updateMission done", res);
            if (res && res.data) {
              this.editItem.missionId = res.data.missionId;
            }
            // this.closeEditDialog();
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
            this.closeEditDialog = true;
            this.$emit("save");
          })
          .catch((e) => {
            console.log("updateMission failed", e);
            let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    onEditQuestion(item, index) {
      this.openQuestionDialog = false;
      console.log("onEditQuestion item:" + JSON.stringify(item));

      if (item) {
        this.editQuestion = Object.assign({}, item);
        this.editQuestion.index = index;
        this.options = [...this.editQuestion.options];
        this.options.forEach((o) => (o.editable = false));
        this.isNewQuestion = false;
      } else {
        this.editQuestion = Object.assign({}, this.defaultEditQuestion);
        this.isNewQuestion = true;
      }
      console.log("isNewQuestion:" + this.isNewQuestion);
      this.openQuestionDialog = true;
    },

    addAnswer() {
      if (!this.inputOption) return;

      // if (!this.editQuestion.options) {
      //   this.editQuestion.options = [];
      // }
      const duplicated = this.options.filter(
        (e) => e.text === this.inputOption.trim()
      );

      if (duplicated.length > 0) {
        this.$swal({
          text: "選項內容重複",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          focusConfirm: true,
          allowEnterKey: true,
        });
        return;
      }
      this.options.push({
        value: makeid(4),
        text: this.inputOption.trim(),
        editable: false,
      });
      this.inputOption = null;
    },
    closeQuestionDialog() {
      console.log("close question dialog");
      this.$refs.questionForm.resetValidation();
      this.$refs.questionForm.reset();
      this.openQuestionDialog = false;
      this.editQuestion = {};
      this.options = [];
    },
    onSaveEditQuestion() {
      const valid = this.$refs.questionForm.validate();

      if (valid) {
        if (!this.editItem.data.questions) {
          this.editItem.data.questions = [];
        }

        let q = Object.assign({}, this.editQuestion);
        q.options = JSON.parse(JSON.stringify(this.options));
        delete q.index;

        console.log("onSaveEditQuestion question:" + JSON.stringify(q));
        if (this.editQuestion.index === -1) {
          this.editItem.data.questions.push(q);
        } else {
          this.editItem.data.questions[this.editQuestion.index] = q;
        }

        this.closeQuestionDialog();
        console.log("editItem after save question:", this.editItem);
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew || this.isNewQuestion) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
